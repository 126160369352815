
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { DataTableHeader } from "@/global-components/data-table/types";
import { ListTopic } from "@/services/v1/admin/learning/topic/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { gpf } from "@/utils/global-functions";
import { listStatusFilter } from "@/utils/global-variables";
import { helper as $h } from "@/utils/helper";
import {
  popupConfirmation,
  popupConfirmationHide,
} from "@/utils/popup/confirmation";
import { popupStatus } from "@/utils/popup/status";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";

const TextField = defineAsyncComponent(
  () => import(`@/global-components/input/text-field/Main.vue`)
);

const Autocomplete = defineAsyncComponent(
  () => import(`@/global-components/input/autocomplete/Main.vue`)
);

const ChipActiveInactive = defineAsyncComponent(
  () => import(`@/global-components/chip/active-inactive.vue`)
);

const TooltipActiveInactive = defineAsyncComponent(
  () => import(`@/global-components/tooltip/active-inactive.vue`)
);

const DataTable = defineAsyncComponent(
  () => import(`@/global-components/data-table/Main.vue`)
);

const ModalFormlTopic = defineAsyncComponent(
  () => import(`./components/modal-form-topic.vue`)
);

const ModalDetailTopic = defineAsyncComponent(
  () => import(`./components/modal-detail-topic.vue`)
);

const servicesV1AdminLearningTopic = async () =>
  import(`@/services/v1/admin/learning/topic`);

export default defineComponent({
  components: {
    TextField,
    Autocomplete,
    ChipActiveInactive,
    TooltipActiveInactive,
    DataTable,
    ModalFormlTopic,
    ModalDetailTopic,
  },
  setup() {
    // START === setup breadcrumb =====================================================
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Data Topik",
        to: `${dashboardPath}/data-topics`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    const tabelHeaders = [
      {
        text: "Banner",
        value: "banner",
        itemClass: "w-36",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Created At",
        value: "createdAt",
        headerClass: "w-32",
      },
      {
        text: "Action",
        value: "action",
        itemClass: "w-20",
        align: "center",
      },
    ] as DataTableHeader[];

    const dataPage = reactive({
      data: [] as any,
      loading: false,

      // for pagination
      page: 1,
      pageSize: 10,
      totalData: 1,
    });

    const queryParams = reactive({
      search: "",
      isActive: "all",
    });

    // START === Functions for handle list topic data =========================================
    const refetchListTopics = ref(false as boolean);
    const getListTopics = async () => {
      const { getAdminLearningTopics } = await servicesV1AdminLearningTopic();

      dataPage.loading = true;

      const page = dataPage.page;
      const pageSize = dataPage.pageSize;

      const params = {
        search: queryParams.search,
        isActive: queryParams.isActive,
        page,
        pageSize,
      } as any;

      if (queryParams.isActive === "all") params.isActive = null;

      try {
        const hit = await getAdminLearningTopics({ params });
        const ds = hit.diagnostic;
        const rs = hit.response;
        // console.log("response get dm ", rs);

        dataPage.data = rs;
        dataPage.totalData = ds.totalData as number;

        // back to default refetch
        refetchListTopics.value = false;
      } catch (err: any) {
        console.error("err getListTopics Admin ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        dataPage.loading = false;
      }
    };

    const dataTopics = computed(() =>
      dataPage.data.map((topic: ListTopic) => ({
        ...topic,
        status: topic.isActive ? "Active" : "Inactive",
      }))
    );

    onMounted(() => {
      getListTopics();
    });

    const handleSearch = () => {
      dataPage.page = 1;
      getListTopics();
    };

    watch(
      () => dataPage.pageSize,
      () => {
        dataPage.page = 1;
        getListTopics();
      }
    );

    watch(
      () => dataPage.page,
      () => {
        getListTopics();
      }
    );

    // for refetch list topic data
    watch(
      () => refetchListTopics.value,
      (newVal) => {
        if (newVal) getListTopics();
      }
    );
    // END === Functions for handle list topic data ===========================================

    // START === add new topic ============================================================
    const setupFormTopic = reactive({
      show: false,
      id: 0,
    });

    const addNewTopic = () => {
      setupFormTopic.show = true;
    };

    const editTopic = (topic: any) => {
      setupFormTopic.show = true;
      setupFormTopic.id = topic.id;
    };
    // END === add new topic ==============================================================

    // START === Function for show modal detail topic =====================================
    const setupDetailTopic = reactive({
      show: false,
      id: 0,
    });

    const showModalDetailTopic = async (topic: any) => {
      setupDetailTopic.show = true;
      setupDetailTopic.id = topic?.id || 0;
    };
    // END === Function for show modal detail topic =======================================

    // START === Function for delete topic ================================================
    const deleteTopicPopup = (data: any): void => {
      popupConfirmation({
        title: "Hapus Data Topik",
        message: `Apakah Anda yakin, ingin menghapus data dengan title : "${data.title}"`,
        buttons: [
          {
            type: "outline",
            text: "Batal",
            action: popupConfirmationHide,
          },
          {
            type: "danger",
            text: "Hapus",
            action: () => {
              fixDeleteTopic(data.id);
              popupConfirmationHide();
            },
          },
        ],
      }).show();
    };

    const fixDeleteTopic = async (topicId: string) => {
      const {
        deleteAdminLearningTopicById,
      } = await servicesV1AdminLearningTopic();

      gpf.gLoading.show();

      try {
        const hit = await deleteAdminLearningTopicById(topicId);

        const messageSuccess = hit?.diagnostic?.message;

        popupStatus({
          type: "s",
          message: messageSuccess,
        }).show();
      } catch (err: any) {
        console.error("err fixDeleteTopic ", err, err?.response);
        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();

        // refetch list data-topic
        getListTopics();
      }
    };
    // END === Function for delete topic ==================================================

    return {
      tabelHeaders,
      getListTopics,
      dataPage,
      queryParams,
      listStatusFilter,
      handleSearch,
      dataTopics,
      refetchListTopics,

      setupFormTopic,
      addNewTopic,
      editTopic,

      $h,
      gpf,
      setupDetailTopic,
      showModalDetailTopic,

      deleteTopicPopup,
      fixDeleteTopic,
    };
  },
});
